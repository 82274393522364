/* path: src/components/Fields/Calendar.jsx */
import React, { useState, useEffect, useRef } from 'react';
import moment from 'moment-timezone';
import 'moment/locale/nl';
import { isSameDay } from './Utils/dateUtils';
import { isDateAvailable } from '../../../../../algorithm/isDateAvailable';
import { isDateAvailableForGiftcard } from '../../../../../algorithm/selectedGiftcardAlgorithm';
import './css/calendar.css';
import './css/mobile.css';

moment.locale('nl');

const Calendar = ({
  selectedDate,
  onSelectDate,
  isExpanded,
  setIsExpanded,
  restaurantData,
  guests,
  reservations,
  onMonthChange,
  giftcard, // new prop for giftcard selection
}) => {
  const [startDate, setStartDate] = useState(null);
  const calendarRef = useRef(null);

  const maxDate = moment().tz('Europe/Amsterdam').add(1, 'year').endOf('day');

  useEffect(() => {
    const today = moment().tz('Europe/Amsterdam').startOf('day');
    // Set startDate to the start of the current month
    const firstOfMonth = today.clone().startOf('month');
    setStartDate(firstOfMonth);
  }, []);

  const generateCalendarGrid = (startDate) => {
    const days = [];
    const monthStart = startDate.clone().startOf('month');
    const monthEnd = startDate.clone().endOf('month');

    const startDayOfWeek = monthStart.isoWeekday();
    const endDayOfWeek = monthEnd.isoWeekday();

    // Add empty cells before the first day if needed
    for (let i = 1; i < startDayOfWeek; i++) {
      days.push(null);
    }

    const today = moment().tz('Europe/Amsterdam').startOf('day');
    for (
      let date = monthStart.clone();
      date.isSameOrBefore(monthEnd, 'day');
      date.add(1, 'day')
    ) {
      const formattedDate = date.format('YYYY-MM-DD');
      let isAvailable = false;
      try {
        // Pass the giftcard parameter to isDateAvailableForGiftcard.
        if (giftcard) {
          isAvailable = isDateAvailableForGiftcard(
            restaurantData,
            formattedDate,
            reservations,
            guests,
            giftcard
          );
        } else {
          isAvailable = isDateAvailable(
            restaurantData,
            formattedDate,
            reservations,
            guests
          );
        }
      } catch (error) {
        console.error('Error checking date availability:', error);
      }

      days.push({
        date: date.clone(),
        isPast: date.isBefore(today, 'day'),
        isFuture: date.isAfter(maxDate, 'day'),
        isCurrentMonth: true,
        isAvailable,
      });
    }

    // Add empty cells after the last day if needed
    for (let i = endDayOfWeek; i < 7; i++) {
      days.push(null);
    }

    return days;
  };

  const handleDateClick = (day) => {
    if (day && day.isAvailable && !day.isPast && !day.isFuture && day.isCurrentMonth) {
      onSelectDate(day.date.toDate());
      setIsExpanded(false);
    }
  };

  const handlePrevMonth = () => {
    const newStartDate = startDate.clone().subtract(1, 'month');
    setStartDate(newStartDate);
    onMonthChange && onMonthChange(newStartDate);
  };

  const handleNextMonth = () => {
    const newStartDate = startDate.clone().add(1, 'month');
    setStartDate(newStartDate);
    onMonthChange && onMonthChange(newStartDate);
  };

  const formatDisplayDate = () => {
    if (!selectedDate) {
      return 'Selecteer een datum';
    }

    const selectedMoment = moment(selectedDate).tz('Europe/Amsterdam').startOf('day');
    const today = moment().tz('Europe/Amsterdam').startOf('day');
    const tomorrow = moment().tz('Europe/Amsterdam').add(1, 'day').startOf('day');

    if (selectedMoment.isSame(today, 'day')) {
      return 'Vandaag';
    } else if (selectedMoment.isSame(tomorrow, 'day')) {
      return 'Morgen';
    } else {
      return selectedMoment.format('DD MMMM YYYY');
    }
  };

  const days = startDate ? generateCalendarGrid(startDate) : [];

  // Break days into weeks of 7 cells
  const weeks = [];
  for (let i = 0; i < days.length; i += 7) {
    weeks.push(days.slice(i, i + 7));
  }

  // Capitalize the month name
  const monthName = startDate ? startDate.format('MMMM YYYY') : '';
  const capitalizedMonthName = monthName.charAt(0).toUpperCase() + monthName.slice(1);

  return (
    <div className="calendar-container" ref={calendarRef}>
      <div
        className="calendar-display"
        onClick={() => {
          setIsExpanded(!isExpanded);
        }}
      >
        <span>{formatDisplayDate()}</span>
        <span className="arrow">
          <svg
            width="12"
            height="12"
            viewBox="0 0 24 24"
            style={{
              transform: isExpanded ? 'rotate(180deg)' : 'rotate(0deg)',
              transition: 'transform 0.2s',
            }}
          >
            <path
              d="M7 10l5 5 5-5"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
            />
          </svg>
        </span>
      </div>
      {isExpanded && startDate && (
        <div className="calendar">
          <div className="calendar-header">
            <button type="button" onClick={handlePrevMonth}>
              &lt;
            </button>
            <span>{capitalizedMonthName}</span>
            <button type="button" onClick={handleNextMonth}>
              &gt;
            </button>
          </div>
          <div className="calendar-weeks-wrapper">
            <table className="calendar-table">
              <thead>
                <tr>
                  {['Ma', 'Di', 'Wo', 'Do', 'Vr', 'Za', 'Zo'].map((day) => (
                    <th key={day}>{day}</th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {weeks.map((week, wIndex) => (
                  <tr key={wIndex}>
                    {week.map((dayObj, dIndex) => {
                      if (dayObj === null) {
                        return (
                          <td key={dIndex} className="empty">
                            <div className="day-square"></div>
                          </td>
                        );
                      } else {
                        const isSelected =
                          selectedDate &&
                          isSameDay(
                            dayObj.date,
                            moment(selectedDate).tz('Europe/Amsterdam')
                          );

                        const classNames = [];
                        if (dayObj.isPast) {
                          classNames.push('gray-out');
                        } else if (dayObj.isAvailable) {
                          classNames.push('available');
                        } else {
                          classNames.push('unavailable');
                        }

                        if (isSelected) {
                          classNames.push('selected');
                        }

                        return (
                          <td
                            key={dIndex}
                            className={classNames.join(' ')}
                            onClick={() => handleDateClick(dayObj)}
                            style={{
                              '--animation-order': wIndex * 7 + dIndex,
                            }}
                          >
                            <div className="day-square">
                              {dayObj.date.date()}
                            </div>
                          </td>
                        );
                      }
                    })}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      )}
    </div>
  );
};

export default Calendar;
