/* path: src/components/TimeSelector.jsx */
import React, { useState, useEffect, useRef } from 'react';
import { getAvailableTimeblocks } from '../../../../../algorithm/getAvailableTimeblocks';
import { getAvailableTimeblocksForGiftcard } from '../../../../../algorithm/selectedGiftcardAlgorithm';
import './css/timeSelector.css';
import './css/mobile.css';

const TimeSelector = ({
  guests,
  formData,
  handleChange,
  field,
  selectedDate,
  timeblocks,
  expanded,
  setCurrentExpandedField,
  restaurantData,
  reservations,
  giftcard, // new prop for giftcard selection
}) => {
  const [isExpanded, setIsExpanded] = useState(expanded || false);
  const [availableTimes, setAvailableTimes] = useState([]);
  const timeSelectorRef = useRef(null);

  useEffect(() => {
    if (selectedDate && guests > 0 && restaurantData) {
      const dateStr = formatDate(selectedDate);

      let availableTimeblocks = {};
      try {
        // Pass the giftcard as the last argument if one is selected.
        if (giftcard) {
          availableTimeblocks = getAvailableTimeblocksForGiftcard(
            restaurantData,
            dateStr,
            reservations,
            guests,
            giftcard
          );
        } else {
          availableTimeblocks = getAvailableTimeblocks(
            restaurantData,
            dateStr,
            reservations,
            guests
          );
        }
      } catch (error) {
        console.error('Error getting available time blocks:', error);
      }

      const times = Object.keys(availableTimeblocks).map((timeKey) => {
        return {
          value: timeKey,
          label: availableTimeblocks[timeKey].name || timeKey,
        };
      });

      setAvailableTimes(times);
    } else {
      setAvailableTimes([]);
    }
  }, [selectedDate, guests, restaurantData, reservations, giftcard]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        timeSelectorRef.current &&
        !timeSelectorRef.current.contains(event.target)
      ) {
        setIsExpanded(false);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  useEffect(() => {
    setIsExpanded(expanded);
  }, [expanded]);

  const handleTimeSelect = (timeValue) => {
    window.selectedTime = timeValue;

    handleChange({
      target: { name: field.id, value: timeValue },
    });
    setIsExpanded(false);
    if (setCurrentExpandedField) {
      setCurrentExpandedField(null);
    }
  };

  const formatDisplayTime = () => {
    if (formData[field.id]) {
      const selected = availableTimes.find(time => time.value === formData[field.id]);
      return selected ? selected.label : 'Selecteer een tijd';
    }
    return 'Selecteer een tijd';
  };

  if (!field) {
    return null;
  }

  return (
    <div className="form-group time-selector-container" ref={timeSelectorRef}>
      <label className="default-text-color" htmlFor={field.id}>
        {field.label}
      </label>

      {!selectedDate ? (
        <p className="info-text">Selecteer eerst een datum.</p>
      ) : (
        <>
          <div
            className="time-display"
            onClick={() => {
              setIsExpanded(!isExpanded);
              if (!isExpanded && setCurrentExpandedField) {
                setCurrentExpandedField('time');
              }
            }}
          >
            <span>{formatDisplayTime()}</span>
            <span className="arrow">
              <svg
                width="12"
                height="12"
                viewBox="0 0 24 24"
                style={{
                  transform: isExpanded ? 'rotate(180deg)' : 'rotate(0deg)',
                  transition: 'transform 0.2s',
                }}
              >
                <path
                  d="M7 10l5 5 5-5"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth="2"
                />
              </svg>
            </span>
          </div>
          {isExpanded && (
            <div className="time-selector">
              {availableTimes.length === 0 ? (
                <div className="no-times">Geen beschikbare tijden.</div>
              ) : (
                <div className="time-options">
                  {availableTimes.map((time) => (
                    <div
                      key={time.value}
                      className={`time-option ${
                        formData[field.id] === time.value ? 'selected' : ''
                      }`}
                      onClick={() => handleTimeSelect(time.value)}
                    >
                      {time.label}
                    </div>
                  ))}
                </div>
              )}
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default TimeSelector;

// Helper function to format date to 'YYYY-MM-DD'
function formatDate(date) {
  if (!date) return '';
  const year = date.getFullYear();
  const month = (`0${date.getMonth() + 1}`).slice(-2);
  const day = (`0${date.getDate()}`).slice(-2);
  return `${year}-${month}-${day}`;
}
