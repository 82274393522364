/* path: src/components/Fields/DateSelector.jsx */
import React, { useState, useEffect } from 'react';
import Calendar from './Calendar';
import './css/calendar.css';
import './css/mobile.css';
import moment from 'moment-timezone';
import { isDateAvailable } from '../../../../../algorithm/isDateAvailable';
import { isDateAvailableForGiftcard } from '../../../../../algorithm/selectedGiftcardAlgorithm';

moment.locale('nl');

const DateSelector = ({
  guests,
  formData,
  handleChange,
  resetFormDataFields,
  timeblocks,
  expanded,
  setCurrentExpandedField,
  restaurantData,
  reservations,
  onMonthChange,
  giftcard, // new prop for giftcard selection
}) => {
  const [suggestedDates, setSuggestedDates] = useState([]);
  const [isExpanded, setIsExpanded] = useState(expanded || false);

  // Track the currently displayed month
  const [displayedMonth, setDisplayedMonth] = useState(moment().tz('Europe/Amsterdam').startOf('month'));

  const handleDateSelect = (date) => {
    window.selectedDate = formatDateForFilter(date);
    handleChange({
      target: { name: 'date', value: date },
    });
    resetFormDataFields(['time']);

    // Collapse the calendar and suggestions once a date is selected
    setIsExpanded(false);

    if (setCurrentExpandedField) {
      setCurrentExpandedField('time');
    }
  };

  const formatDateForFilter = (date) => {
    if (!date) return '';
    const year = date.getFullYear();
    const month = (`0${date.getMonth() + 1}`).slice(-2);
    const day = (`0${date.getDate()}`).slice(-2);
    return `${year}-${month}-${day}`;
  };

  // Wrap onMonthChange to update displayedMonth
  const handleCalendarMonthChange = (newMonthDate) => {
    setDisplayedMonth(newMonthDate);
    onMonthChange && onMonthChange(newMonthDate);
  };

  // Find three available dates in the displayedMonth, only future dates
  useEffect(() => {
    if (!restaurantData || !reservations || !displayedMonth) return;

    const today = moment().tz('Europe/Amsterdam').startOf('day');
    const monthStart = displayedMonth.clone().startOf('month');
    const monthEnd = displayedMonth.clone().endOf('month');
    let foundDates = [];

    let date = monthStart.clone();
    while (date.isSameOrBefore(monthEnd, 'day') && foundDates.length < 3) {
      const formattedDate = date.format('YYYY-MM-DD');
      let available = false;
      try {
        // Pass the giftcard to the algorithm when one is selected.
        if (giftcard) {
          available = isDateAvailableForGiftcard(
            restaurantData,
            formattedDate,
            reservations,
            guests,
            giftcard
          );
        } else {
          available = isDateAvailable(
            restaurantData,
            formattedDate,
            reservations,
            guests
          );
        }
      } catch (error) {
        console.error('Error checking date availability:', error);
      }

      // Only add the date if it's available and not in the past
      if (available && date.isSameOrAfter(today, 'day')) {
        foundDates.push(date.clone());
      }

      date.add(1, 'day');
    }

    setSuggestedDates(foundDates);
  }, [restaurantData, reservations, guests, displayedMonth, giftcard]);

  const handleSuggestionClick = (date) => {
    handleDateSelect(date);
  };

  const formatSuggestionTitle = (dateMoment) => {
    const weekday = dateMoment.format('dddd'); // e.g. "woensdag"
    const capitalizedWeekday = weekday.charAt(0).toUpperCase() + weekday.slice(1);

    const day = dateMoment.format('D'); // e.g. 7
    const monthName = dateMoment.format('MMMM'); // januari
    const capitalizedMonthName = monthName.charAt(0).toUpperCase() + monthName.slice(1);

    return `${capitalizedWeekday} ${day} ${capitalizedMonthName}`;
  };

  const formatSuggestionSubtitle = (dateMoment) => {
    return `${dateMoment.format('DD-MM-YYYY')} beschikbaar`;
  };

  return (
    <div className="form-group date-selector-container">
      <label htmlFor="date" className="default-text-color">
        Datum
      </label>
      <div className={`calendar-content-container ${isExpanded ? 'calendar-expanded' : 'calendar-collapsed'}`}>
        <Calendar
          selectedDate={formData.date ? formData.date : null}
          onSelectDate={handleDateSelect}
          isExpanded={isExpanded}
          setIsExpanded={setIsExpanded}
          restaurantData={restaurantData}
          guests={guests}
          reservations={reservations}
          onMonthChange={handleCalendarMonthChange}
          giftcard={giftcard} // pass giftcard prop to Calendar
        />
        
        {isExpanded && (
          <div className="boxes-container">
            <h3>Enkele Suggesties</h3>
            {suggestedDates.map((dateMoment, index) => (
              <div
                key={index}
                className="suggestion-box"
                onClick={() => handleSuggestionClick(dateMoment.toDate())}
              >
                <span style={{fontWeight:"500"}}>{formatSuggestionTitle(dateMoment)}</span>
                <div>{formatSuggestionSubtitle(dateMoment)}</div>
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default DateSelector;

function formatDateForFilter(date) {
  if (!date) return '';
  const year = date.getFullYear();
  const month = (`0${date.getMonth() + 1}`).slice(-2);
  const day = (`0${date.getDate()}`).slice(-2);
  return `${year}-${month}-${day}`;
}
