/* path: src/components/Utils/stepOneUtils.js */
import React from 'react';
import { getDutchDayOfWeek } from './getDatesDutch';

// Utility function to check if current selection matches any mededeling
export const isMededelingActive = (selectedDate, selectedTime, mededelingen) => {
  if (!selectedDate || !mededelingen || mededelingen.length === 0) {
    return null;
  }

  const selectedDay = getDutchDayOfWeek(selectedDate);
  const selectedDateObj = new Date(selectedDate);
  let selectedTimeObj = null;

  if (selectedTime) {
    selectedTimeObj = new Date(`1970-01-01T${selectedTime}:00`); // Time only
  }

  for (let med of mededelingen) {
    // Check if selected date is within mededeling's date range
    const medStartDate = new Date(med.startDate);
    const medEndDate = new Date(med.endDate);
    if (selectedDateObj < medStartDate || selectedDateObj > medEndDate) {
      continue;
    }

    // Check if selected day is in mededeling's daysOfWeek
    if (!med.daysOfWeek.includes(selectedDay)) {
      continue;
    }

    // If mededeling has time range and selectedTime is provided
    if (med.startTime && med.endTime && selectedTimeObj) {
      const medStartTimeObj = new Date(`1970-01-01T${med.startTime}:00`);
      const medEndTimeObj = new Date(`1970-01-01T${med.endTime}:00`);
      if (selectedTimeObj >= medStartTimeObj && selectedTimeObj <= medEndTimeObj) {
        return med.mededeling;
      }
    } else {
      // If no time range or selectedTime is not provided
      return med.mededeling;
    }
  }

  return null;
};

// Helper function to format a Date object into a 'YYYY-MM-DD' string.
export function formatDate(date) {
  if (!date) return null;
  const year = date.getFullYear();
  const month = (`0${date.getMonth() + 1}`).slice(-2);
  const day = (`0${date.getDate()}`).slice(-2);
  return `${year}-${month}-${day}`;
}

/**
 * Renders a general notification message replacing any detected URL or email address
 * with a clickable link.
 *
 * - If an email address is detected (e.g. "email@mywebsite.com"), the link's text displays the full email address,
 *   and the link uses a mailto: URL.
 * - If a website URL is detected (e.g. "mywebsite.be"), the entire URL is replaced with a clickable link that shows "Klik hier".
 *
 * The regex first matches email addresses and then website URLs.
 *
 * @param {string} text - The notification text to render.
 * @returns {React.Node|null} The rendered notification or null if no text is provided.
 */
export function renderGeneralNotification(text) {
  if (!text) return null;
  // Combined regex: first alternative matches email addresses, second alternative matches URLs.
  const combinedRegex = /\b((?:[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,})|(?:(?:https?:\/\/)?(?:www\.)?[\w.-]+\.(?:com|net|nl|be)(?:\/\S*)?))\b/gi;
  // Split the text on matches.
  const parts = text.split(combinedRegex);
  return parts.map((part, index) => {
    if (part && part.match && part.match(combinedRegex)) {
      if (part.includes('@')) {
        // Detected an email address: render the full email address.
        return (
          <a
            key={index}
            href={`mailto:${part}`}
            style={{ textDecoration: 'underline', color: 'inherit' }}
          >
            {part}
          </a>
        );
      } else {
        // Detected a website URL: render a link with text "Klik hier".
        return (
          <a
            key={index}
            href={part.startsWith('http') ? part : `http://${part}`}
            target="_blank"
            rel="noopener noreferrer"
            style={{ textDecoration: 'underline', color: 'inherit' }}
          >
            Klik hier
          </a>
        );
      }
    }
    return part;
  });
}
