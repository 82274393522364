/* path: src/algorithm/selectedGiftcardAlgorithm.js */
/**
 * selectedGiftcardAlgorithm.js
 * Directory: src/algorithm
 * 
 * This module implements a giftcard-specific algorithm for determining available time blocks 
 * and date availability. When a nonempty 'selectedGiftcard' is provided, the algorithm ignores 
 * the standard opening hours (i.e. the schemeSettings and keys like "openinghours-${mealType}") 
 * and instead relies solely on exceptions. In this mode, only exceptions of type "Opening" or 
 * "Uitzondering" that contain the specified giftcard (as a comma-separated value in exception.giftcards)
 * are considered (while always including "Sluiting" exceptions to block times). All the standard filtering 
 * (uurOpVoorhand, dagenInToekomst, and not showing times or dates in the past) is then applied via the 
 * existing getAvailableTimeblocks algorithm.
 * 
 * It also exports a utility function, getAvailableGiftcards, that returns the combined unique giftcards
 * (from the giftcards field in all exceptions).
 */

/**
 * Returns an array of unique giftcards (as defined in exception.giftcards) from the data.
 * @param {Object} data - The main data object expected to have an "exceptions" array.
 * @returns {Array<string>} An array of unique giftcard strings.
 */
function getAvailableGiftcards(data) {
	const exceptions = data.exceptions || [];
	const giftcardSet = new Set();
  
	exceptions.forEach(exception => {
	  if (
		exception.giftcards &&
		typeof exception.giftcards === 'string' &&
		exception.giftcards.trim() !== ""
	  ) {
		const cards = exception.giftcards.split(',').map(card => card.trim());
		cards.forEach(card => {
		  if (card !== "") {
			giftcardSet.add(card);
		  }
		});
	  }
	});
  
	return Array.from(giftcardSet);
  }
  
  /**
   * Returns the available time blocks for a given date and reservation parameters when a selectedGiftcard
   * is provided. When a giftcard is selected, the algorithm disregards the standard opening hours by removing
   * all keys that start with "openinghours-" and any top-level schemeSettings. It also filters the exceptions 
   * so that only "Sluiting" exceptions and "Opening"/"Uitzondering" exceptions that list the selected giftcard are kept.
   * All the standard filtering (uurOpVoorhand, dagenInToekomst, not showing past times, etc.) is then applied by delegating 
   * to the standard getAvailableTimeblocks algorithm.
   * 
   * @param {Object} data - The main data object.
   * @param {string} dateStr - The target date as "YYYY-MM-DD".
   * @param {Array} reservations - An array of reservation objects.
   * @param {number} guests - The number of guests.
   * @param {string} selectedGiftcard - The giftcard identifier that must be present in the exception.
   * @returns {Object} An object of available time blocks (keys are time strings, e.g., "07:00").
   */
  function getAvailableTimeblocksForGiftcard(data, dateStr, reservations, guests, selectedGiftcard) {
	// If no giftcard is provided, fall back to the standard algorithm.
	if (!selectedGiftcard || selectedGiftcard.trim() === "") {
	  const { getAvailableTimeblocks } = require('./getAvailableTimeblocks');
	  return getAvailableTimeblocks(data, dateStr, reservations, guests);
	}
  
	// Clone the original data object (shallow clone is sufficient if nested objects are not modified elsewhere)
	const clonedData = { ...data };
  
	// Filter the exceptions: always keep "Sluiting"; for "Opening" or "Uitzondering",
	// include only those that have the selected giftcard (case-insensitive).
	if (data.exceptions) {
	  clonedData.exceptions = data.exceptions.filter(exception => {
		if (exception.type === 'Sluiting') {
		  return true;
		} else if (exception.type === 'Opening' || exception.type === 'Uitzondering') {
		  if (exception.giftcards && typeof exception.giftcards === 'string') {
			const cards = exception.giftcards.split(',').map(card => card.trim().toLowerCase());
			return cards.includes(selectedGiftcard.toLowerCase());
		  }
		  return false;
		}
		return false;
	  });
	}
  
	// Remove the standard opening hours and schemeSettings so that only exception-based availability is used.
	Object.keys(clonedData).forEach(key => {
	  if (key.startsWith("openinghours-") || key === "schemeSettings") {
		delete clonedData[key];
	  }
	});
  
	// Delegate to the standard getAvailableTimeblocks algorithm.
	const { getAvailableTimeblocks } = require('./getAvailableTimeblocks');
	return getAvailableTimeblocks(clonedData, dateStr, reservations, guests);
  }
  
  /**
   * Checks whether the specified date is available for reservation given the selectedGiftcard.
   * It applies the same filtering as isDateAvailable (e.g. not showing dates in the past) but uses the 
   * giftcard-specific available time blocks.
   * 
   * @param {Object} data - The main data object.
   * @param {string} dateStr - The date string "YYYY-MM-DD".
   * @param {Array} reservations - An array of reservation objects.
   * @param {number} guests - The number of guests.
   * @param {string} selectedGiftcard - The selected giftcard identifier.
   * @returns {boolean} True if at least one time block is available; false otherwise.
   */
  function isDateAvailableForGiftcard(data, dateStr, reservations, guests, selectedGiftcard) {
	// If no giftcard is selected, fall back to the standard algorithm.
	if (!selectedGiftcard || selectedGiftcard.trim() === "") {
	  const { isDateAvailable } = require('./isDateAvailable');
	  return isDateAvailable(data, dateStr, reservations, guests);
	}
  
	const timeblocks = getAvailableTimeblocksForGiftcard(data, dateStr, reservations, guests, selectedGiftcard);
	return Object.keys(timeblocks).length > 0;
  }
  
  module.exports = {
	getAvailableGiftcards,
	getAvailableTimeblocksForGiftcard,
	isDateAvailableForGiftcard
  };
  