/* path: src/components/StepOne.jsx */
import React, { useState, useEffect, useRef } from 'react';
import Title from './Title';
import DateSelector from './Fields/DateSelector';
import TimeSelector from './Fields/TimeSelector';
import GuestNumberSelector from './Fields/GuestSelector';
import MaxGuestMessage from './Notifications/index';
import MededelingNotification from './Notifications/MededelingNotification';
import useApi from '../../../Hooks/useApi'; // Adjust the path as needed
import moment from 'moment-timezone';
import { isMededelingActive, formatDate, renderGeneralNotification } from './Utils/stepOneUtils';
import { getAvailableGiftcards } from '../../../algorithm/selectedGiftcardAlgorithm';
import './css/notifications.css';

const StepOne = ({
  title,
  timeblocks,
  formData,
  handleChange,
  resetFormDataFields,
  setCurrentStep,
  fields,
  generalNotification,
  expandedField,
  generalSettings,
  mededeling,
  restaurantData,
}) => {
  // Giftcard selection state
  const [giftcardChoice, setGiftcardChoice] = useState(null); // "Ja" or "Nee"
  const [selectedGiftcard, setSelectedGiftcard] = useState(null);

  // Get available giftcards using getAvailableGiftcards function
  const availableGiftcards = getAvailableGiftcards(restaurantData);

  const [currentExpandedField, setCurrentExpandedField] = useState(expandedField || 'guest');
  const [guests, setGuests] = useState(1);
  const [reservations, setReservations] = useState([]);
  const api = useApi();

  // Ref to store the previous number of guests
  const previousGuestsRef = useRef(formData['guests'] || 1);

  // Use maxGasten from generalSettings, default to 10 if not available
  const maxGuests = parseInt(generalSettings?.maxGasten, 10) || 10;

  const handleNext = (e) => {
    e.preventDefault();
    setCurrentStep(2);
  };

  const timeField = fields.find((field) => field.id === 'time');
  const guestNumberField = fields.find((field) => field.id === 'guests');

  const numGuests = parseInt(formData[guestNumberField.id], 10) || 0;

  // Determine if a notice should be shown based on settings and guest number
  const showNotice =
    (generalSettings?.showNoticeForMaxGuests === 'Ja' && numGuests > maxGuests) ||
    (generalSettings?.showNoticeForMaxGuests !== 'Ja' && numGuests > maxGuests);

  // Determine the message type based on settings
  const noticeType =
    generalSettings?.showNoticeForMaxGuests === 'Ja' ? 'call' : 'limit';

  // Determine if the time has been selected
  const isTimeSelected = !!formData[timeField.id];

  // Effect to handle guest changes
  useEffect(() => {
    const previousGuests = previousGuestsRef.current;
    const currentGuests = formData[guestNumberField.id];

    if (previousGuests !== currentGuests) {
      // Reset the date and time fields when guests change
      resetFormDataFields(['date', 'time']);
      // Fetch reservations again if guests change, as availability may have changed
      fetchReservationsForMonth();
    }

    // Update the ref with the current number of guests
    previousGuestsRef.current = currentGuests;
  }, [formData, guestNumberField.id, resetFormDataFields]);

  // Function to fetch reservations for a given month
  const fetchReservationsForMonth = async (monthMoment = null) => {
    try {
      // If no month is passed, use current month
      const baseDate = monthMoment || moment().tz('Europe/Amsterdam');
      const beginDate = baseDate.clone().startOf('month').format('YYYY-MM-DD');
      const endDate = baseDate.clone().endOf('month').format('YYYY-MM-DD');
      const restaurantId = window.restaurantId;
      const endpoint = `${window.baseDomain}api/slots/${restaurantId}/${beginDate}/${endDate}`;

      const data = await api.get(endpoint, { noCache: true });
      setReservations(data || []);
    } catch (error) {
      console.error('Error fetching reservations:', error);
    }
  };

  // Fetch reservations when component mounts or guests change
  useEffect(() => {
    fetchReservationsForMonth();
  }, [guests]);

  // Callback for when the month changes in the Calendar
  const handleMonthChange = (newMonthDate) => {
    // newMonthDate is a moment object representing the start of the displayed month
    fetchReservationsForMonth(newMonthDate);
  };

  // Determine if there is an active mededeling
  const activeMededeling = isMededelingActive(
    formData.date ? formatDate(formData.date) : null,
    formData.time,
    mededeling
  );

  useEffect(() => {
    if (generalNotification && generalNotification.trim() !== '') {
      document.documentElement.style.setProperty('--notification-margin', '65%');
    } else {
      document.documentElement.style.removeProperty('--notification-margin');
    }
  }, [generalNotification]);

  // Handlers for giftcard selection
  const handleGiftcardChoiceChange = (e) => {
    const value = e.target.value;
    setGiftcardChoice(value);
    // Reset giftcard selection if choice changes
    setSelectedGiftcard(null);
    // Reset form data related to guests, date, and time when giftcard choice changes
    resetFormDataFields(['guests', 'date', 'time']);
    setGuests(1);
  };

  const handleSelectedGiftcardChange = (e) => {
    setSelectedGiftcard(e.target.value);
    // Reset form data when giftcard selection changes
    resetFormDataFields(['guests', 'date', 'time']);
    setGuests(1);
  };

  // Inline style for the select boxes as specified
  const selectStyle = {
    padding: "10px 12px",
    border: "var(--background-color) 1px solid",
    borderRadius: "4px",
    fontSize: "16px",
    transition: "border-color 0.3s ease",
    width: "100%"
  };

  // New CSS style for giftcard rows to display the label on one row and the select box on the next.
  const giftcardRowStyle = {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    marginBottom: "16px"
  };

  return (
    <form onSubmit={handleNext}>
      <Title title={title} subtitle="Stap 1/3" />

      {generalNotification && generalNotification.trim() !== '' && (
        <div className="general-notification">
          {renderGeneralNotification(generalNotification)}
        </div>
      )}

      {activeMededeling && (
        <MededelingNotification message={activeMededeling} />
      )}

      {/* Giftcard Selection Step */}
      {availableGiftcards && availableGiftcards.length > 0 && (
        <div className="giftcard-selection">
          <div className="form-group" style={giftcardRowStyle}>
            <label className="default-text-color" htmlFor="giftcard-choice">
              Heb je een Cadeaubon?
            </label>
            <select
              id="giftcard-choice"
              style={selectStyle}
              value={giftcardChoice || ""}
              onChange={handleGiftcardChoiceChange}
            >
              <option value="" disabled>
                Selecteer
              </option>
              <option value="Ja">Ja</option>
              <option value="Nee">Nee</option>
            </select>
          </div>
          {giftcardChoice === "Ja" && (
            <div className="form-group" style={giftcardRowStyle}>
              <label className="default-text-color" htmlFor="selected-giftcard">
                Kies je Cadeaubon
              </label>
              <select
                id="selected-giftcard"
                style={selectStyle}
                value={selectedGiftcard || ""}
                onChange={handleSelectedGiftcardChange}
              >
                <option value="" disabled>
                  Selecteer een cadeaubon
                </option>
                {availableGiftcards.map((giftcard, index) => (
                  <option key={index} value={giftcard}>
                    {giftcard}
                  </option>
                ))}
              </select>
            </div>
          )}
        </div>
      )}

      {/* Only reveal guest selector and subsequent fields if giftcard selection is complete */}
      {(!availableGiftcards ||
        availableGiftcards.length === 0 ||
        giftcardChoice === "Nee" ||
        (giftcardChoice === "Ja" && selectedGiftcard)) && (
        <>
          <GuestNumberSelector
            setGuests={setGuests}
            formData={formData}
            handleChange={handleChange}
            field={guestNumberField}
            expanded={currentExpandedField === 'guest'}
            setCurrentExpandedField={setCurrentExpandedField}
            maxGuests={maxGuests}
            generalSettings={generalSettings}
          />

          {showNotice && (
            <MaxGuestMessage
              maxGuests={maxGuests}
              noticePhoneNumber={
                generalSettings?.showNoticeForMaxGuests === 'Ja'
                  ? generalSettings.noticePhoneNumber
                  : ''
              }
              type={noticeType}
            />
          )}

          {!showNotice && formData[guestNumberField.id] && (
            <DateSelector
              guests={numGuests}
              formData={formData}
              handleChange={handleChange}
              resetFormDataFields={resetFormDataFields}
              expanded={currentExpandedField === 'date'}
              setCurrentExpandedField={setCurrentExpandedField}
              timeblocks={timeblocks}
              restaurantData={restaurantData}
              reservations={reservations}
              onMonthChange={handleMonthChange}
              giftcard={giftcardChoice === "Ja" ? selectedGiftcard : null}
            />
          )}

          {!showNotice && formData.date && (
            <TimeSelector
              guests={numGuests}
              formData={formData}
              handleChange={handleChange}
              field={timeField}
              selectedDate={formData.date}
              expanded={currentExpandedField === 'time'}
              setCurrentExpandedField={setCurrentExpandedField}
              timeblocks={timeblocks}
              restaurantData={restaurantData}
              reservations={reservations}
              giftcard={giftcardChoice === "Ja" ? selectedGiftcard : null}
            />
          )}

          {!showNotice && isTimeSelected && (
            <button type="submit" className="submit-button">
              Volgende
            </button>
          )}
        </>
      )}
    </form>
  );
};

export default StepOne;
