// file-path: ./src/components/NewReservation.jsx

import React, { useState, useEffect } from 'react';
import { fields } from './Config/formConfig.js';
import './css/newReservation.css';
import './css/mobile.css';
import './css/animations.css';
import './css/style.css';
import useFonts from '../../Hooks/useFonts.js';
import transformFonts from '../../Utils/transformFonts.js';
import computeAlignmentValues from '../../Utils/computeAlignmentValues.js';
import useApi from '../../Hooks/useApi.js';
import ReservationModal from './Structure/PopUp.js';
import ReservationFullScreen from './Structure/FullScreen.js';
import ReservationForm from './Structure/Form.js';
import ReservationStyles from './Style/ReservationStyles.js';
import { defaultAppearanceData, defaultFonts, defaultSettings, defaultTheme } from './Style/defaults.js';
import { darkenHexColor, isColorTooBright } from './Style/colorUtils.js'; 

// Utility function to extract numbers from fields like { "$numberInt": "31" }
const extractNumber = (field) => {
  if (typeof field === 'object' && field !== null && '$numberInt' in field) {
    return parseInt(field['$numberInt'], 10);
  }
  return field;
};

const NewReservation = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isClosing, setIsClosing] = useState(false);
  const [restaurantData, setRestaurantData] = useState(null);
  const api = useApi();

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsClosing(true);
    setTimeout(() => {
      setIsModalOpen(false);
      setIsClosing(false);
    }, 300);
  };

  useEffect(() => {
    if (isModalOpen) {
      document.body.classList.add('modal-open');
    } else {
      document.body.classList.remove('modal-open');
    }

    return () => {
      document.body.classList.remove('modal-open');
    };
  }, [isModalOpen]);

  useEffect(() => {
    const fetchRestaurantData = async () => {
      try {
        const endpoint = `${window.baseDomain}api/restaurant/${window.restaurantId}`;
        const data = await api.get(endpoint, { noCache: true });

        // If we receive a 404, 500, or invalid response,
        // immediately set defaults to avoid infinite loading or errors.
        if (!data || data.status === 404 || data.status === 500) {
          setRestaurantData({
            settings: defaultSettings,
            fonts: defaultFonts,
            colors: defaultAppearanceData,
            theme: defaultTheme,
          });
          return;
        }

        // Merge received data with defaults (handling partial data safely)
        const mergedSettings = { ...defaultSettings, ...(data.settings || {}) };
        const mergedFonts = { ...defaultFonts, ...(data.fonts || {}) };
        const mergedColors = { ...defaultAppearanceData, ...(data.colors || {}) };
        const mergedTheme = { ...defaultTheme, ...(data.theme || {}) };

        // Parse numeric fields if necessary
        const parsedTheme = {
          id: extractNumber(mergedTheme.id),
          title: mergedTheme.title,
          color: mergedTheme.color,
          image: mergedTheme.image,
        };

        const parsedSettings = {
          ...mergedSettings,
          storedNumber: extractNumber(mergedSettings.storedNumber),
        };

        // Create a new merged data object
        const mergedData = {
          ...data,
          settings: parsedSettings,
          fonts: mergedFonts,
          colors: mergedColors,
          theme: parsedTheme,
        };

        setRestaurantData(mergedData);
        console.log('Merged Restaurant Data:', mergedData);
      } catch (error) {
        console.error('Error fetching restaurant data:', error);
        // On error (e.g. network error), set defaults so the user can still proceed
        setRestaurantData({
          settings: defaultSettings,
          fonts: defaultFonts,
          colors: defaultAppearanceData,
          theme: defaultTheme,
        });
      }
    };

    fetchRestaurantData();
  }, [api]);

  // Load custom fonts (if any) after we have restaurantData
  useFonts(restaurantData);

  // If restaurantData is not yet set, show a quick loading text
  if (!restaurantData) {
    return <div>Loading...</div>;
  }

  // Destructure with fallback to defaults to prevent runtime errors
  const {
    colors = defaultAppearanceData,
    theme = defaultTheme,
    settings = defaultSettings,
    timeblocks = [],
    menu = [],
    mededeling = '',
  } = restaurantData || {};

  const generalSettings = restaurantData['general-settings'] || {};
  window.generalSettings = generalSettings; // Optional: Retain if needed globally

  const alignment = settings?.alignment || 'fullScreenColor';
  const { alignmentBottomValue, alignmentTopValue, alignmentMarginValue } = computeAlignmentValues(alignment);

  let { fonts } = restaurantData;
  fonts = transformFonts(fonts || defaultFonts);

  const { titleFont, subtitleFont, buttonFont, labelFont } = fonts;

  const title = settings?.pageTitle || 'Reserveer een Tafel';
  const backgroundType = colors?.backgroundType || 'solid';

  let formSectionBackgroundStyle = '';
  if (backgroundType === 'solid') {
    formSectionBackgroundStyle = `
      .form-section {
        background-color: ${colors?.backgroundColor || '#FFFFFF'} !important;
      }
    `;
  } else if (backgroundType === 'gradient') {
    const startColor = colors?.gradientStartColor || '#FFFFFF';
    const endColor = colors?.gradientEndColor || '#000000';
    formSectionBackgroundStyle = `
      .form-section {
        background-image: linear-gradient(${startColor}, ${endColor}) !important;
      }
    `;
  } else if (backgroundType === 'animated') {
    formSectionBackgroundStyle = `
      .form-section {
        background-color: ${colors?.backgroundColor || '#FFFFFF'} !important;
      }
      .form-section.animated-background {
        background-size: 800% 800%;
      }
    `;
  }


  const formContent = (
    <ReservationForm
      title={title}
      settings={settings}
      generalSettings={generalSettings}
      fields={fields}
      timeblocks={timeblocks}
      menu={menu}
      mededeling={mededeling}
      restaurantData={restaurantData}
    />
  );

  
  

  const backgroundBlur = settings?.backgroundBlur || 'sharpPicture';

  const hexToRgba = (hex, opacity) => {
    hex = hex.replace('#', '');
    const r = parseInt(hex.substring(0, 2), 16);
    const g = parseInt(hex.substring(2, 4), 16);
    const b = parseInt(hex.substring(4, 6), 16);
    return `rgba(${r}, ${g}, ${b}, ${opacity})`;
  };

  const backgroundColorTransparant = hexToRgba(colors?.backgroundColor || '#FFFFFF', 0.03);

  // Determine bubble-view-color
  let bubbleViewColor = colors?.backgroundColor || '#000';
  if (isColorTooBright(bubbleViewColor)) {
    bubbleViewColor = darkenHexColor(bubbleViewColor, 0.2);
  }

  return (
    <div id='mateza'>
    <div
      className={`new-reservation-page ${window.viewMode === 'popup' ? 'popup-mode' : ''} ${
        backgroundBlur === 'blurBackground' ? 'blur-background' : ''
      }`}
      style={{
        '--widget-background-color': colors?.widgetBackgroundColor,
        '--widget-text-color': colors?.widgetTextColor,
        '--text-color': colors?.textColor,
        '--background-color': colors?.backgroundColor,
        '--background-color-transparant': backgroundColorTransparant,
        '--container-color': colors?.containerColor,
        '--button-color': colors?.buttonColor,
        '--button-text-color': colors?.buttonTextColor,
        '--alignment-bottom': alignmentBottomValue,
        '--alignment-top': alignmentTopValue,
        '--alignment-margin': alignmentMarginValue,
        '--bubble-view-color': bubbleViewColor,
      }}
    >
      {window.viewMode === 'full-screen' ? (
        <ReservationFullScreen theme={theme} formContent={formContent} />
      ) : (
        <ReservationModal
          isModalOpen={isModalOpen}
          isClosing={isClosing}
          openModal={openModal}
          closeModal={closeModal}
          formContent={formContent}
        />
      )}

      <ReservationStyles
        fonts={{ labelFont, titleFont, subtitleFont, buttonFont }}
        formSectionBackgroundStyle={formSectionBackgroundStyle}
      />

      {window.viewMode === 'full-screen' ? (
          <div
            className={`form-section ${backgroundType === 'animated' ? 'animated-background' : ''}`}
            style={
              alignment === 'fullScreenPicture'
                ? { minHeight: '0', bottom: 0 }
                : { position: 'absolute', right: 0, minHeight: '120vh', bottom: 0 }
            }
          >
            {formContent}
          </div>
        ) : (
          <div className=""></div>
        )}
    </div>
    </div>
  );
};

export default NewReservation;
